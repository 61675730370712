import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { COUNTRY } from '../../configs/env';
import { countryLangs } from '../../configs/country-langs';
import { WpFeedDataPropsI, WpPostNodeI } from '../../types/WpFeedData';
import { getFeaturedImgForBlogLayout } from '../../helpers/getFeaturedImgForBlogLayout';
import { BlogFeedPage } from './new-blog-feed.page';
import { BlogPostI } from './BlogPost.interface';

const MainBlogPage = (props: WpFeedDataPropsI): React.FC | ReactElement => {
  const { t } = useTranslation();

  const meta = {
    title: t('meta_BlogTitle'),
    description: t('meta_BlogDescription'),
  };

  const returnWpPostsByCountry = (edge: WpPostNodeI) => {
    if (COUNTRY === 'rs') {
      return edge.node.country === COUNTRY || !edge.node.country;
    } else {
      return edge.node.country === COUNTRY;
    }
  };

  const posts: Array<BlogPostI> = props.data.allWordpressPost.edges
    .filter((edge: WpPostNodeI) => edge.node.categories.filter((category) => category.slug === 'blog').length > 0)
    .filter((edge: WpPostNodeI) => returnWpPostsByCountry(edge))
    .map((edge: WpPostNodeI, index) => {
      return {
        key: index,
        id: edge.node.id,
        title: edge.node.title,
        excerpt: edge.node.excerpt,
        featuredImg: getFeaturedImgForBlogLayout(edge.node),
        date: edge.node.date,
        url: `/${countryLangs[COUNTRY]}/blog/${edge.node.slug}`,
        categories: edge.node.categories,
      };
    });
  return <BlogFeedPage type="blog" posts={posts} meta={meta} />;
};

export const allPostsQuery = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          id
          slug
          path
          title
          excerpt
          status
          date
          template
          country
          format
          categories {
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MainBlogPage;
